// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-11203(f74f2c215c)-C22/01/2025-12:20:21-B22/01/2025-12:53:09' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-11203(f74f2c215c)-C22/01/2025-12:20:21-B22/01/2025-12:53:09",
  branch: "master",
  latestTag: "6.1",
  revCount: "11203",
  shortHash: "f74f2c215c",
  longHash: "f74f2c215c2962e41662a6710bab930f7efaf1c6",
  dateCommit: "22/01/2025-12:20:21",
  dateBuild: "22/01/2025-12:53:09",
  buildType: "Ansible",
  } ;
